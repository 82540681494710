import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { LIVE_SHOW_SCHEDULE } from "@/config/constants";

dayjs.extend(timezone);

const Ticker = () => {
  const router = useRouter();
  const [show, setShow] = useState(null);

  useEffect(() => {
    const findLiveShow = () => {
      const userTimeZone = dayjs.tz.guess();
      const now = dayjs().tz(userTimeZone);

      const liveShow = LIVE_SHOW_SCHEDULE.find((show) => {
        const showStartTime = dayjs()
          .tz("America/Toronto")
          .day(show.day)
          .hour(parseInt(show.startTime.split(":")[0]))
          .minute(parseInt(show.startTime.split(":")[1]))
          .second(parseInt(show.startTime.split(":")[2]))
          .tz(userTimeZone);

        const showEndTime = dayjs()
          .tz("America/Toronto")
          .day(show.day)
          .hour(parseInt(show.endTime.split(":")[0]))
          .minute(parseInt(show.endTime.split(":")[1]))
          .second(parseInt(show.endTime.split(":")[2]))
          .tz(userTimeZone);

        return now.isAfter(showStartTime) && now.isBefore(showEndTime);
      });
      setShow(liveShow);
    };

    findLiveShow();

    const interval = setInterval(findLiveShow, 60000);

    return () => clearInterval(interval);
  }, []);

  if (!show) return null;

  const isHomepage = router.pathname === "/";

  return (
    <div className="mb-4 rounded-md bg-gradient-to-r from-[#8c52ff] from-25% to-[#ff914d] to-75% lg:rounded-lg">
      <Link
        href={show?.link ?? ""}
        target="_blank"
        className="flex items-center justify-center"
      >
        <div
          className={`whitespace-wrap text-right text-sm font-extrabold leading-[16px] text-white lg:w-auto ${isHomepage ? "lg:text-[32px]" : "lg:text-[28px]"}`}
        >
          <span>{show?.name ?? ""}</span>
        </div>
        <Image
          src={show?.banner ?? ""}
          alt="Live Stream Banner"
          width={275}
          height={30}
          className={`w-[34%] lg:h-[63px] lg:w-auto`}
        />
      </Link>
    </div>
  );
};

export default Ticker;
