import {
  BETTING_RECORD_HEADINGS,
  IS_PROD,
  MLB_STANDINGS_HEADINGS,
  NBA_STANDINGS_HEADINGS,
  NCAAF_STANDINGS_HEADINGS,
  NFL_STANDINGS_HEADINGS,
  NHL_STANDINGS_HEADINGS,
} from "./constants";

export const CONSENSUS_PICKS_MENU = {
  all: {
    id: "all",
    icon: "selectAll",
    name: "Upcoming Games",
    noGameUrl: "/guides/",
  },
  nfl: {
    id: "nfl",
    icon: "selectNFL",
    name: "NFL",
    noGameUrl: "/nfl/tips/",
  },
  nba: {
    id: "nba",
    icon: "selectNBA",
    name: "NBA",
    noGameUrl: "/guides/nba-betting-guide-picks-odds-tips/",
  },
  wnba: {
    id: "wnba",
    icon: "wnbaCategory",
    name: "WNBA",
    noGameUrl: "/",
  },
  mlb: {
    id: "mlb",
    icon: "selectMLB",
    name: "MLB",
    noGameUrl: "/mlb/tips/",
  },
  nhl: {
    id: "nhl",
    icon: "selectNHL",
    name: "NHL",
    noGameUrl: "/nhl/how-to-bet-puck-lines-in-the-nhl/",
  },
  ncaaf: {
    id: "ncaaf",
    icon: "selectNCAAF",
    name: "NCAAF",
    noGameUrl: "/ncaaf/tips/",
  },
  ncaab: {
    id: "ncaab",
    icon: "ncaabCategory",
    name: "NCAAB",
    noGameUrl: "/ncaab/tips/",
  },
  soccer: {
    id: "soccer",
    icon: "selectSoccer",
    name: "Soccer",
    noGameUrl: "/",
  },
};

export const FOOTER_EVENTS_MENUS = {
  title: "EVENTS",
  paths: {
    "Kentucky Derby": "/horses/kentucky-derby",
    "World Series": "/mlb/world-series/",
    "Super Bowl": "/nfl/super-bowl",
  },
};

export const FOOTER_LEAGUE_MENUS = {
  NFL: {
    "NFL PICKS TODAY": "/nfl/picks/",
    "NFL Betting Odds": "/nfl/odds/",
    "NFL Sports Betting News": "/nfl/",
    "NFL Betting Tips ": "/nfl/tips/",
    "Super Bowl Betting 2024": "/nfl/super-bowl/",
  },
  NBA: {
    "NBA PICKS TODAY": "/nba/picks/",
    "NBA Betting Odds": "/nba/odds/",
    "NBA Sports Betting News": "/nba/",
    "NBA Betting Tips ": "/nba/tips/",
    "How to Bet NBA Finals 2024": "/nba/finals/",
  },
  WNBA: {
    "WNBA PICKS TODAY": "/wnba/picks/",
    "WNBA Betting Odds": "/wnba/odds/",
    "WNBA Sports Betting News": "/wnba/",
    "WNBA Betting Tips ": "/wnba/tips/",
    "How to Bet WNBA Finals 2024": "/wnba/finals/",
  },
  MLB: {
    "MLB PICKS TODAY": "/mlb/picks/",
    "MLB Betting Odds": "/mlb/odds/",
    "MLB Sports Betting News": "/mlb/",
    "MLB Betting Tips": "/mlb/tips/",
    "MLB World Series 2024": "/mlb/world-series/",
  },
  NHL: {
    "NHL PICKS TODAY": "/nhl/picks/",
    "NHL Betting Odds": "/nhl/odds/",
    "NHL Sports Betting News": "/nhl/",
    "NHL Betting Tips": "/nhl/betting-explained-tips/",
    "Bet Stanley Cup 2023 - 2024": "/nhl/stanley-cup/",
  },
  CollegeFootball: {
    "COLLEGE FOOTBALL PICKS": "/ncaaf/picks/",
    "College Football Odds": "/ncaaf/odds/",
    "College Football Betting Guide": "/guides/college-football-betting-guide/",
  },
  CollegeBasketballPicks: {
    "COLLEGE BASKETBALL PICKS": "/ncaab/picks/",
    "College Basketball Odds": "/ncaab/odds/",
    "College Basketball Betting Guide":
      "/guides/college-basketball-betting-guide/",
    "College Basketball Best Bets Today": "/ncaab/best-bets-today/",
  },
  Casino: {
    "BEST REAL MONEY CASINOS": "/best-online-casinos/real-money/",
    "Best US Online Casinos": "/best-online-casinos/",
    "OCG Casino Review": "/best-online-casinos/onlinecasinogames-review/",
    "Wild Casino Review": "/best-online-casinos/wild-casino-review/",
    "DuckyLuck Casino Review": "/best-online-casinos/duckyluck-casino/",
  },
  HorseRacing: {
    "FREE HORSE RACING PICKS": "https://horses.bettingnews.com/",
    "Aqueduct Picks": "https://horses.bettingnews.com/aqueduct",
    "Santa Anita Picks":
      "https://horses.bettingnews.com/santa-anita-race-track",
    "Saratoga Picks": "https://horses.bettingnews.com/saratoga",
    "Parx Picks": "https://horses.bettingnews.com/parx",
    "Kentucky Derby Picks":
      "/articles/horses/kentucky-derby-149-churchill-downs-full-card-analysis",
    "Gulfstream Park Picks": "https://horses.bettingnews.com/gulfstream-park",
  },
  Sportbooks: {
    "BEST US SPORTSBOOKS": "/usa-sportsbooks/",
    "BetOnline Sportsbook Review": "/usa-sportsbooks/betonline/",
    "MyBookie Sportsbook Review": "/usa-sportsbooks/mybookie/",
    "BetWhale Sportsbook Review": "/usa-sportsbooks/betwhale/",
    "SportsBetting Sportsbook Review": "/usa-sportsbooks/sportsbetting/",
    "Buy Premium Sports Picks": "/picks/buy-premium-sports-betting-picks/",
    "Sports Betting Guides": "/guides/",
  },
};

export const FOOTER_REGULATED_AREAS_MENU = {
  AZ: "/legal/arizona",
  CO: "/legal/colorado/",
  CT: "/legal/connecticut/",
  IL: "/legal/illinois/",
  IN: "/legal/indiana/",
  IA: "/legal/iowa/",
  LA: "/legal/louisiana/",
  MI: "/legal/michigan/",
  NJ: "/legal/nj/",
  NY: "/legal/new-york/",
  ON: "/canada/ontario/",
  PA: "/legal/pennsylvania/",
  TN: "/legal/tennessee/",
  VA: "/legal/virginia/",
  WA: "/legal/washington/",
  WY: "/legal/wyoming/",
};

export const HOMEPAGE_CATEGORIES_MENU = {
  boxing: "Boxing",
  golf: "Golf",
  horses: "Horse Racing",
  mlb: "MLB",
  mma: "MMA",
  nba: "NBA",
  ncaab: "NCAAB",
  ncaaf: "NCAAF",
  nfl: "NFL",
  nhl: "NHL",
  regulation: "Regulation",
  soccer: "Soccer",
  tennis: "Tennis",
  f1: "Formula One",
  industry: "Betting Industry",
  nascar: "NASCAR",
  wnba: "WNBA",
};

export const LEAGUE_ODDS_MENU = {
  moneyLine: { title: "Moneyline" },
  pointSpread: { title: "Point Spread" },
  total: { title: "Total" },
};

export const LEAGUE_PICKS_MENU = {
  moneyLine: {
    title: "Moneyline",
    headings: ["Teams", "Best Line", "Consensus", "Public Bet %"],
  },
  pointSpread: {
    title: "Point Spread",
    headings: ["Teams", "Best Spread", "Consensus", "Public Bet %"],
  },
  total: {
    title: "Total",
    headings: ["Teams", "Best Total", "Consensus", "Public Bet %"],
  },
};

export const LEAGUE_STANDINGS_MENUS = {
  mlb: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    league: {
      title: "League",
      headings: MLB_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: MLB_STANDINGS_HEADINGS,
    },
  },
  nba: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NBA_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NBA_STANDINGS_HEADINGS,
    },
  },
  wnba: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NBA_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NBA_STANDINGS_HEADINGS,
    },
  },
  nfl: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NFL_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NFL_STANDINGS_HEADINGS,
    },
  },
  nhl: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    league: {
      title: "League",
      headings: NHL_STANDINGS_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NHL_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NHL_STANDINGS_HEADINGS,
    },
  },
  ncaaf: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
  },
  ncaab: {
    bettingRecord: {
      title: "Betting Record",
      headings: BETTING_RECORD_HEADINGS,
    },
    conference: {
      title: "Conference",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
    division: {
      title: "Division",
      headings: NCAAF_STANDINGS_HEADINGS,
    },
  },
};

export const MATCHUP_ODDS_MENU = {
  moneyLine: { title: "Moneyline" },
  pointSpread: { title: "Point Spread" },
  total: { title: "Total" },
};

export const MATCHUP_PICKS_MENU = {
  moneyLine: { title: "Moneyline" },
  pointSpread: { title: "Point Spread" },
  total: { title: "Total" },
  score: { title: "Score" },
};

export const ARTICLE_PREDICTIONS_MENU = {
  pointSpread: { title: "Spread" },
  total: { title: "Total" },
  moneyLine: { title: "Moneyline" },
};

export const NAV_MENUS = {
  Picks: {
    menuTitle: "Picks",
    menuOptions: {
      NFL: "/nfl/picks/",
      NBA: "/nba/picks/",
      WNBA: "/wnba/picks/",
      MLB: "/mlb/picks/",
      NHL: "/nhl/picks/",
      NCAAF: "/ncaaf/picks/",
      NCAAB: "/ncaab/picks/",
      "Soccer Picks": {
        league: true,
        menuTitle: "SOCCER",
        path: "/soccer/picks",
        menuOptions: {
          "English Premier League":
            "/soccer/picks?league=english-premier-league",
          "US MLS": "/soccer/picks?league=mls",
          "Mexico Liga MX": "/soccer/picks?league=liga-mx",
          "UEFA Champions League": "/soccer/picks?league=uefa-champions-league",
        },
      },
      "Picks Services": "/picks/buy-premium-sports-betting-picks/",
    },
  },
  Odds: {
    menuTitle: "Odds",
    menuOptions: {
      NFL: "/nfl/odds/",
      NBA: "/nba/odds/",
      WNBA: "/wnba/odds/",
      MLB: "/mlb/odds/",
      NHL: "/nhl/odds/",
      NCAAF: "/ncaaf/odds/",
      NCAAB: "/ncaab/odds/",
      "Soccer Odds": {
        league: true,
        menuTitle: "SOCCER",
        path: "/soccer/odds",
        menuOptions: {
          "English Premier League":
            "/soccer/odds?league=english-premier-league",
          "US MLS": "/soccer/odds?league=mls",
          "Mexico Liga MX": "/soccer/odds?league=liga-mx",
          "UEFA Champions League": "/soccer/odds?league=uefa-champions-league",
        },
      },
    },
  },
  News: {
    menuTitle: "News",
    menuOptions: {
      Shows: "/sports-betting-shows/",
      NFL: "/nfl/",
      NBA: "/nba/",
      WNBA: "/wnba/",
      MLB: "/mlb/",
      NHL: "/nhl/",
      NCAAF: "/ncaaf/",
      NCAAB: "/ncaab/",
      F1: "/category/articles/f1/",
      NASCAR: "/category/articles/nascar/",
      "Horse Racing": "/category/articles/horses/",
      MMA: "/category/articles/mma/",
      Boxing: "/category/articles/boxing/",
      Golf: "/category/articles/golf/",
      "Soccer News": {
        league: true,
        menuTitle: "SOCCER",
        path: "/soccer/",
        menuOptions: {
          "English Premier League": "/soccer/?league=english-premier-league",
          "US MLS": "/soccer/?league=mls",
          // "Mexico Liga MX": "/category/articles/soccer/?league=liga-mx", * NO POSTS IN THIS LEAGUE YET - HIDDEN UNTIL FURTHER NOTICE *
          "UEFA Champions League": "/soccer/?league=uefa-champions-league",
        },
      },
      Tennis: "/category/articles/tennis/",
      "Miscellaneous Sports": "/category/articles/miscellaneous-sports/",
      "Betting Industry": "/category/articles/industry/",
    },
  },
  Sportsbooks: {
    menuTitle: "Sportsbooks",
    menuOptions: {
      "Best US Sportsbooks": "/usa-sportsbooks/",
      "Best Canada Sportsbooks": "/canada-sportsbooks/",
      "Best Horse Racing Betting Sites": "/best-horse-racing-betting-sites/",
    },
  },
  "Gambling Sites": {
    menuTitle: "Gambling Sites",
    menuOptions: {
      "Best US Online Casinos": "/best-online-casinos/",
      "Best US Online Real Money Casinos": "/best-online-casinos/real-money/",
      "Best Canada Online Casinos": "/best-canada-online-casinos/",
      "Best Online Poker Sites": "/best-online-poker-sites/",
    },
  },
  Guides: {
    menuTitle: "Guides",
    menuOptions: {
      "Sports Betting Guides": "/guides/",
      "Picks Guide": "/picks/",
      "Daily Fantasy": "/daily-fantasy",
      "Betting Tools": "/tools/",
      "Team Rivalries": "/rivalry/",
      "Legal Betting Sites": "/legal-online-sports-betting-sites-in-the-usa/",
    },
  },
  "Horse Racing": {
    menuTitle: "Today's Picks",
    menuOptions: {
      "Top Tracks": "https://horses.bettingnews.com/",
      "Track Bias": "https://horses.bettingnews.com/track-bias/",
      Races: "https://horses.bettingnews.com/races/",
      "Jockey Analysis": "https://horses.bettingnews.com/jockey/",
      "Trainer Analysis": "https://horses.bettingnews.com/trainer/",
    },
  },
};

export const NCAAF_DIVISIONS_MENU = {
  divisionIA: { name: "Conferences", slug: "I_A" },
  aac: { name: "ACC", slug: "aac" },
  big12: { name: "Big 12", slug: "big_12" },
  bigTen: { name: "Big Ten", slug: "big_ten" },
  pac12: { name: "PAC 12", slug: "pac_12" },
  sec: { name: "SEC", id: 23, slug: "sec" },
};

export const NCAAB_DIVISIONS_MENU = {
  divisionI: { name: "Conferences", slug: "Division_I" },
  bigTen: { name: "Big 10", slug: "big_ten" },
  bigEast: { name: "Big East", slug: "big_east" },
  sec: { name: "Southeastern Conference", id: 23, slug: "sec" },
  pac12: { name: "Pac 12", slug: "pac_12" },
  acc: { name: "Atlantic Coast", slug: "acc" },
  mountainWest: { name: "Mountain West", slug: "mountain_west" },
  american: { name: "American Athletic", slug: "american" },
  wcc: { name: "West Coast", slug: "wcc" },
  cusa: { name: "Conference USA", slug: "cusa" },
};

export const SOCCER_MENU = {
  soccer: { name: "All Leagues", slug: "soccer" }, // used this slug while other league are integrated
  mls: { name: "US MLS", slug: "mls" },
  "english-premier-league": {
    name: "English Premier League",
    slug: "english-premier-league",
  },
  "liga-mx": { name: "Mexico Liga MX", slug: "liga-mx" },
  "uefa-champions-league": {
    name: "UEFA Champions League",
    slug: "uefa-champions-league",
  },
};

export const SPORTS_MENU = {
  soccer: { name: "All Leagues", slug: "soccer" },
  mls: { name: "US MLS", slug: "mls" },
  "english-premier-league": {
    name: "English Premier League",
    slug: "english-premier-league",
  },
  // "liga-mx": { name: "Mexico Liga MX", slug: "liga-mx" }, * NO POSTS IN THIS LEAGUE YET - HIDDEN UNTIL FURTHER NOTICE *
  "uefa-champions-league": {
    name: "UEFA Champions League",
    slug: "uefa-champions-league",
  },
};

export const SIDE_MENU = {
  nhl: {
    id: "nhl",
    icon: "guideNHL",
    name: "NHL Betting Guide",
    url: "/guides/nhl-betting-guide/",
  },
  nfl: {
    id: "nfl",
    icon: "guideNFL",
    name: "NFL Betting Guide",
    url: "/nfl-betting-guide/",
  },
  nba: {
    id: "nba",
    icon: "guideNBA",
    name: "NBA Betting Guide",
    url: "/guides/nba-betting-guide/",
  },
  mlb: {
    id: "mlb",
    icon: "mlbGuide",
    name: "MLB Betting Guide",
    url: "/mlb/tips/",
  },
  wnba: {
    id: "wnba",
    icon: "guideWNBA",
    name: "WNBA  Betting Guide",
    url: "/guides/wnba-betting-guide/",
  },
  ncaaf: {
    id: "ncaaf",
    icon: "guideNCAAF",
    name: "College Football Betting Guide",
    url: "/guides/college-football-betting-guide/",
  },
  kentucky: {
    id: "kentucky",
    icon: "guideKentucky",
    name: "Kentucky Derby Betting Guide",
    url: "/best-horse-racing-betting-sites/kentucky-derby/",
  },
  SuperBowl: {
    id: "superBowl",
    icon: "guideSuperBowl",
    name: "Super Bowl Betting Guide",
    url: "/nfl/super-bowl/",
  },
  MarchMadness: {
    id: "marchMadness",
    icon: "guideMarchMadness",
    name: "March Madness Betting Guide",
    url: "/march-madness/",
  },
};

export const PAGES_TAGS = [
  "best_bets",
  "nfl",
  "nba",
  "mlb",
  "nhl",
  "ncaaf",
  "ncaab",
  "soccer",
];
