import { NAV_MENUS } from "@/config/menus";

export function formatHorseTracks(tracks) {
  return tracks.reduce((trackMap, item) => {
    if (item && item.name && item.url) {
      trackMap[item.name] = item.url;
    }
    return trackMap;
  }, {});
}

// Combine horse track URLs with hardcoded horse racing URLs from menu constants
export function addHorseTracksToNav(horseTracks) {
  const navMenus = JSON.parse(JSON.stringify(NAV_MENUS)); // Deep clone
  const initialHorseRacingNavOptions =
    NAV_MENUS["Horse Racing"]?.menuOptions || {};
  const initialHorseRacingNavOptionKeys = Object.keys(
    initialHorseRacingNavOptions
  );
  const firstKey = initialHorseRacingNavOptionKeys.shift(); // Get the first key and remove it from the array

  const firstOption = {};
  if (firstKey) {
    firstOption[firstKey] = initialHorseRacingNavOptions[firstKey];
  }

  const remainingOptions = initialHorseRacingNavOptionKeys.reduce(
    (acc, key) => {
      acc[key] = initialHorseRacingNavOptions[key];
      return acc;
    },
    {}
  );

  navMenus["Horse Racing"].menuOptions = {
    ...firstOption,
    ...horseTracks,
    ...remainingOptions,
  };

  return navMenus;
}
