import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const wpURL = process.env.NEXT_PUBLIC_BETTING_NEWS_WORDPRESS_URL;
const frontendURL = process.env.NEXT_PUBLIC_BETTING_NEWS_FRONTEND_URL;

const wpPaths = [
  "wp-json",
  "wp-admin",
  "wp-content",
  "wp-includes",
  "feed",
  "comments",
  "xmlrpc",
];

const wpURLRegExp = new RegExp(`${wpURL}`, "g");

// Transforms head tag data to ensure that no WP content URLs are exposed
export function transformHeadTagURLs(head, canonical) {
  const shouldAddTrailingSlashToCanonical =
    !!canonical && !canonical.endsWith("/");

  let headWithAllURLsTransformed = head;
  // We're seeing an issue where some URLs do not have the trailing slash in the Yoast head.
  // If the canonical URL does not have a trailing slash, we replace it with a URL that does.
  if (shouldAddTrailingSlashToCanonical) {
    const canonicalURLRegExp = new RegExp(String.raw`${canonical}`, "g");
    const canonicalWithTrailingSlash = `${canonical}/`;
    headWithAllURLsTransformed = headWithAllURLsTransformed?.replace(
      canonicalURLRegExp,
      canonicalWithTrailingSlash
    );

    // In the event that there was originally a URL WITH a trailing slash somewhere
    // in the head, we check for it and correct the case of 2 trailing slashes.
    const canonicalURLRegExpWithExtraTrailingSlash = new RegExp(
      String.raw`${canonical}//`,
      "g"
    );
    headWithAllURLsTransformed = headWithAllURLsTransformed?.replace(
      canonicalURLRegExpWithExtraTrailingSlash,
      canonicalWithTrailingSlash
    );
  }
  headWithAllURLsTransformed = headWithAllURLsTransformed?.replace(
    wpURLRegExp,
    frontendURL
  );
  wpPaths.forEach((path) => {
    const wpPathRegExp = new RegExp(`${frontendURL}/${path}`, "g");
    headWithAllURLsTransformed = headWithAllURLsTransformed?.replace(
      wpPathRegExp,
      `${wpURL}/${path}`
    );
  });
  return headWithAllURLsTransformed;
}

export const cleanFormatting = (str) =>
  str
    .replace("&quot;", '"')
    .replace("&amp;", "&")
    .replace("&lt;", "<")
    .replace("&gt;", ">")
    .replace("%3A", ":")
    .replace("%2F", "/");

export function formatFaqSchemaForHeadTag(wpFaqSchema) {
  let faqSchema = null;
  if (wpFaqSchema) {
    const schema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: wpFaqSchema.map((item) => ({
        '@type': 'Question',
        name: item?.q,
        acceptedAnswer: {
          '@type': 'Answer',
          text: item?.a,
        },
      })),
    };
    faqSchema = JSON.stringify(schema);
  }
  return faqSchema;
}

export function formatGamesSchema(games) {
  const schema = Object.values(games ?? {}).map((game) => ({
    '@context': 'https://schema.org',
    '@type': 'SportsEvent',
    sport: game?.league?.toUpperCase(),
    name: `${game?.awayTeam?.name} vs. ${game?.homeTeam?.name}`,
    startDate: dayjs(game?.startTime).local().format(),
    location: {
      '@context': 'https://schema.org',
      '@type': 'Place',
      name: game?.venue?.name,
      address: {
        '@context': 'https://schema.org',
        '@type': 'PostalAddress',
        addressLocality: game?.venue?.location,
      },
    },
  }));
  return schema.length === 1
    ? JSON.stringify(schema[0])
    : JSON.stringify(schema);
}

export const formatGameSchema = (game) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'SportsEvent',
    identifier: game?.id,
    sport: game?.league.toUpperCase().replaceAll('-', ' '),
    name: `${game?.away?.fullName} vs. ${game?.home?.fullName}`,
    startDate: dayjs(game?.startTime).local().format(),
    awayTeam: {
      '@context': 'https://schema.org',
      '@type': 'SportsTeam',
      identifier: game?.away?.id,
      name: game?.away?.fullName,
      sport: game?.league.toUpperCase().replaceAll('-', ' '),
      image: {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        url: game?.trends?.away?.logo,
      },
      memberOf: {
        '@context': 'https://schema.org',
        '@type': 'SportsOrganization',
        name: game?.league.toUpperCase().replaceAll('-', ' '),
      },
    },
    homeTeam: {
      '@context': 'https://schema.org',
      '@type': 'SportsTeam',
      identifier: game?.home?.id,
      name: game?.home?.fullName,
      sport: game?.league.toUpperCase().replaceAll('-', ' '),
      image: {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        url: game?.trends?.home?.logo,
      },
      memberOf: {
        '@context': 'https://schema.org',
        '@type': 'SportsOrganization',
        name: game?.league.toUpperCase().replaceAll('-', ' '),
      },
    },
    organizer: {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: game?.league.toUpperCase().replaceAll('-', ' '),
      url: `${game?.seo?.canonical}`,
    },
    performer: {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: `${game?.away?.fullName} vs. ${game?.home?.fullName}`,
      url: `${game?.seo?.canonical}`,
    },
    eventStatus: 'https://schema.org/EventScheduled',
    eventAttendanceMode: 'https://schema.org/MixedEventAttendanceMode',
    description: game?.title,
    image: {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      url: 'https://bettingnewscontent.s3.us-east-2.amazonaws.com/bn-logo.png',
    },
    about: {
      image: {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        url: 'https://bettingnewscontent.s3.us-east-2.amazonaws.com/bn-logo.png',
      },
    },
    location: {
      '@context': 'https://schema.org',
      '@type': 'Place',
      name: game?.venue?.name,
      address: {
        '@context': 'https://schema.org',
        '@type': 'PostalAddress',
        addressLocality: game?.venue?.location || '-',
      },
    },
    url: `https://www.bettingnews.com/${game?.league.toLowerCase()}/matchup/${game?.id.toLowerCase()}/`,
  };
  return schema.length === 1
    ? JSON.stringify(schema[0])
    : JSON.stringify(schema);
};

function updateSchema(schemaString) {
  if (!schemaString) {
    return schemaString;
  }

  let schema;
  try {
    schema = JSON.parse(schemaString);
  } catch (error) {
    return schemaString;
  }

  function updateUrls(item) {
    for (let key in item) {
      if (typeof item[key] === "string") {
        if (
          key.includes("Url") &&
          item[key].includes("wp.bettingnews.com/wp-content/uploads")
        ) {
          continue;
        } else if (item[key].includes("wp.bettingnews.com")) {
          item[key] = item[key].replace(
            "wp.bettingnews.com",
            "www.bettingnews.com"
          );
        }
      } else if (typeof item[key] === "object") {
        updateUrls(item[key]);
      }
    }
  }

  if (Array.isArray(schema["@graph"])) {
    schema["@graph"].forEach(updateUrls);
  }

  let schemaStringUpdated = JSON.stringify(schema);

  schemaStringUpdated = schemaStringUpdated.replace(/\\"/g, '"');

  return schemaStringUpdated;
}

export function replacePrivateBaseURLFromFullHead(seo) {
  const wrongBaseURL = process.env.NEXT_PUBLIC_IP_SERVER_URL;
  const formattedSeo = { ...seo };

  const replaceURL = (url, newBaseURL) =>
    url.startsWith(wrongBaseURL) ? url.replace(wrongBaseURL, newBaseURL) : url;

  if (seo?.canonical) {
    formattedSeo.canonical = replaceURL(seo.canonical, frontendURL);
  }

  if (seo?.ogImage?.src) {
    formattedSeo.ogImage = {
      ...seo.ogImage,
      src: replaceURL(seo.ogImage.src, wpURL),
    };
  }

  if (seo?.schema) {
    const schema = seo.schema.replace(
      new RegExp(wrongBaseURL, "g"),
      frontendURL
    );
    const formattedSchema = updateSchema(schema);
    formattedSeo.schema = formattedSchema;
  }
  return formattedSeo;
}

export const formatOrganizationSchema = (seo, isSchema = true) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Betting News',
    alternateName: 'Betting News',
    identifier: 'BN',
    description: seo?.metaDesc,
    url: seo?.canonical ?? 'https://www.bettingnews.com',
    logo: 'https://bettingnewscontent.s3.us-east-2.amazonaws.com/bn-logo.png',
    sameAs: [
      'https://www.twitch.tv/bettingnews',
      'https://www.facebook.com/yourbettingnews/',
      'https://twitter.com/YourBettingNews',
      'https://www.tiktok.com/@yourbettingnews',
      'https://www.youtube.com/@YourBettingNews',
      'https://www.playback.tv/bettingnews',
    ],
    brand: {
      '@context': 'https://schema.org',
      '@type': 'Brand',
      name: 'Betting News',
      alternateName: 'Betting News',
      identifier: 'BN',
      url: seo?.canonical,
      description: seo?.metaDesc,
      image:
        'https://bettingnewscontent.s3.us-east-2.amazonaws.com/bn-logo.png',
    },
    keywords: [
      'Sport Betting News',
      'Betting News',
      'Sports Betting News',
      'Best Betting Sites',
      'Betting Tips',
      'Odds Comparison',
      'Live Betting Odds',
      'Sports Betting Analysis',
    ],
  };

  if (!isSchema) {
    return schema;
  }

  return schema.length === 1
    ? JSON.stringify(schema[0])
    : JSON.stringify(schema);
};

export const formatNewsArticleSchema = (post) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: post?.title,
    isAccessibleForFree: true,
    datePublished: post?.date,
    dateModified: post?.date,
    image:
      post?.featuredImage?.sourceUrl ??
      'https://bettingnewscontent.s3.us-east-2.amazonaws.com/bn-logo.png',
    description: post?.description,
    mainEntityOfPage: {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      '@id': `https://www.bettingnews.com${post?.uri ?? ''}`,
    },
    author: post?.author?.name
      ? {
          '@context': 'https://schema.org',
          '@type': 'Person',
          name: post?.author?.name ?? 'Betting News',
          image:
            post?.author?.avatar ??
            'https://www.bettingnews.com/_next/static/media/betting-news-logo.6cf0bd16.svg',
          jobTitle: 'Editor',
          url: `https://www.bettingnews.com${post?.author?.uri ?? ''}`,
        }
      : formatOrganizationSchema(post?.seo, false),
    copyrightNotice:
      'Copyright © 2019 - 2024 Betting News All Rights Reserved.',
    copyrightYear: 2019,
    creativeWorkStatus: 'Published',
    inLanguage: {
      '@context': 'https://schema.org',
      '@type': 'Language',
      name: 'English',
      alternateName: 'English',
      identifier: 'EN',
    },
  };
  return schema.length === 1
    ? JSON.stringify(schema[0])
    : JSON.stringify(schema);
};

export function removeCanonical(htmlContent) {
  return htmlContent?.replace(/<link [^>]*rel="canonical"[^>]*>/g, "");
}

export function formatSeoAuthor(author) {
  return {
    name: author?.node?.name ?? '',
    twitterUsername: author?.node?.seo?.social?.twitter
      ? `@${author.node.seo.social.twitter}`
      : '',
  };
}

export function formatOpenGraphImage(image) {
  return {
    src: image?.sourceUrl,
    type: image?.mimeType,
    height: image?.mediaDetails?.height,
    width: image?.mediaDetails?.width,
  };
}

export function formatOgImageWithFullHead(fullHead) {
  if (!fullHead) {
    return null;
  }
  const imageUrlRegex = /<meta property="og:image" content="([^"]+)"/;
  const imageWidthRegex = /<meta property="og:image:width" content="([^"]+)"/;
  const imageHeightRegex = /<meta property="og:image:height" content="([^"]+)"/;
  const imageTypeRegex = /<meta property="og:image:type" content="([^"]+)"/;

  function extractContent(regex, string) {
    const match = regex.exec(string);
    return match ? match[1] : null;
  }

  const ogImage = {
    src: extractContent(imageUrlRegex, fullHead),
    width: extractContent(imageWidthRegex, fullHead),
    height: extractContent(imageHeightRegex, fullHead),
    type: extractContent(imageTypeRegex, fullHead),
  };
  const filteredOgImage = Object.fromEntries(
    Object.entries(ogImage)?.filter(([_, v]) => v != null)
  );
  return Object.keys(filteredOgImage).length ? filteredOgImage : null;
}
