import * as dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import {
  formatCollegeGames,
  formatGames,
  formatLeagueGames,
  formatWBNAGames,
} from "@/utilities/games";
import { formatHorseTracks } from "@/utilities/horseTracks";
import { formatMatchup, formatRivalry } from "@/utilities/matchup";
import { formatLeagueStandings } from "@/utilities/standings";

dayjs.extend(utc);
dayjs.extend(timezone);

async function fetchAPI(path) {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_BETTING_NEWS_API_URL}${path}`,
    { next: { revalidate: 60 * 60 } }
  );

  if (res.ok) {
    const json = await res.json();
    if (json.errors) {
      throw new Error("Failed to fetch API");
    }
    return json.data;
  }
  return null;
}

async function fetchHorsesAPI(path) {
  const res = await fetch(`${process.env.NEXT_PUBLIC_HORSES_API_URL}${path}`, {
    next: { revalidate: 60 * 60 },
  });

  if (res.ok) {
    const json = await res.json();
    if (json.errors) {
      throw new Error("Failed to fetch API");
    }
    return json.data;
  }
  return null;
}

export async function getGame(matchupPath, gameKey) {
  const gameKeyDate = dayjs(gameKey.substr(0, 10)); // Extract the date part directly
  const currentDate = dayjs().tz("America/Toronto").format("YYYY-MM-DD");
  let updatedGameKey = gameKey;

  const urlParamsGame = new URLSearchParams();

  // Pull 120 days stored game_key
  if (gameKeyDate.isBefore(currentDate)) {
    urlParamsGame.set("force_query", false);
    updatedGameKey = `${gameKey}?${urlParamsGame.toString()}`;
  } else {
    urlParamsGame.set("force_query", true);
    updatedGameKey = `${gameKey}?${urlParamsGame.toString()}`;
  }
  const data = await fetchAPI(`${matchupPath}${updatedGameKey}`);

  const league = data?.league;
  const season = data?.season;
  const awayTeamId = data?.away_team?.team_id;
  const homeTeamId = data?.home_team?.team_id;
  const urlTeams = `/${league}/teams/${season}/${awayTeamId}/${homeTeamId}`;
  const stats = await fetchAPI(urlTeams);
  const week = data?.week;

  return formatMatchup(data, stats, week);
}

async function getGamesRequest(offset, responses) {
  const date = dayjs().tz("America/Toronto").format("YYYY-MM-DD-HH:00");
  const response = await fetchAPI(
    `/league/games/date/${date}?limit=20&offset=${offset}`
  );

  if (response.games.length) {
    const data = response.games;
    responses.push(...data);
    if (response?.has_more) {
      offset += 20;
      return getGamesRequest(offset, responses);
    }
  }
  return response?.has_more;
}

async function getCollegeGamesRequest(offset, responses) {
  const date = dayjs().tz("America/Toronto").format("YYYY-MM-DD-HH:00");

  const response = await fetchAPI(
    `/college/games/date/${date}?limit=20&offset=${offset}`
  );

  if (response.games.length) {
    const data = response.games;
    responses.push(...data);
    if (response?.has_more) {
      offset += 20;
      return getCollegeGamesRequest(offset, responses);
    }
  }
  return response?.has_more;
}

async function getSoccerGamesRequest(offset, responses) {
  const date = dayjs().tz("America/Toronto").format("YYYY-MM-DD-HH:00");
  const response = await fetchAPI(
    `/soccer/games/dates/${date}?limit=20&offset=${offset}`
  );
  if (response?.games?.length) {
    const data = response?.games;
    responses.push(...data);
    if (response?.has_more) {
      offset += 20;
      return await getSoccerGamesRequest(offset, responses);
    }
  }
  return response?.has_more;
}

async function getWNBAGamesRequest(offset, responses) {
  const date = dayjs().tz("America/Toronto").format("YYYY-MM-DD-HH:00");
  const response = await fetchAPI(
    `/wnba/games/dates/${date}?limit=20&offset=${offset}`
  );
  if (response?.games?.length) {
    const data = response?.games;
    responses.push(...data);
    if (response?.has_more) {
      offset += 20;
      return await getWNBAGamesRequest(offset, responses);
    }
  }
  return response?.has_more;
}

export async function getGames() {
  const responses = [];
  const hasMore = await getGamesRequest(0, responses);
  if (!hasMore) return formatGames(responses);
}

export async function getCollegeGames() {
  const responses = [];
  const hasMore = await getCollegeGamesRequest(0, responses);
  if (!hasMore) return formatCollegeGames(responses);
}

export async function getSoccerGames() {
  const responses = [];
  const hasMore = await getSoccerGamesRequest(0, responses);
  if (!hasMore) return formatLeagueGames(responses);
}

export async function getWNBAGames() {
  const responses = [];
  const hasMore = await getWNBAGamesRequest(0, responses);
  if (!hasMore) return formatWBNAGames(responses);
}

export async function getHorseTracks() {
  const data = await fetchHorsesAPI("/api/v1/tracks_today");
  return formatHorseTracks(data);
}

export async function getLeagueStandings(league) {
  // League seasons are hardcoded for now. Other seasons are broken.
  const seasons = {
    nba: "2023-2024",
    wnba: "2024",
    nhl: "20222023",
    mlb: "2023",
    nfl: "2024",
    ncaaf: "2023",
    ncaab: "2023",
  };
  const data = await fetchAPI(`/${league}/standings/${seasons[league]}`);
  return formatLeagueStandings(data, league);
}

export async function getRivalry(teams) {
  const data = await fetchAPI(`/league/games/matchup/${teams}`);
  return formatRivalry(data);
}
