// Format WP affiliate content / data
export function formatAffiliates(data) {
  const edges = data?.affiliates?.edges || [];
  const affiliates = [
    "BetOnline",
    "MyBookie",
    "betwhale",
    "SportsBetting",
    "Bovada",
    "sportbet",
    "BetUS",
    "everygame",
    "BetDSI",
  ];

  const orderedData = edges.sort((a, b) => {
    const indexA = affiliates.indexOf(a?.node?.title || "");
    const indexB = affiliates.indexOf(b?.node?.title || "");
    return (
      (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
    );
  });

  return orderedData.map((edge) => {
    const item = edge?.node;
    if (!item) return {};

    const { id, title } = item;
    const affiliateInfo =
      {
        ...item.affiliateInfo,
      } || {};

    return {
      id,
      title,
      ...affiliateInfo,
    };
  });
}
