import { PAGES_TAGS } from "@/config/menus";
import { getDataPagesWithTags, getPagesWithTags } from "@/pages/api/apiWP";

export async function formatPagesWithTags(tags) {
  const pages = await Promise.all(
    tags.map((tag) => getDataPagesWithTags([tag]))
  );
  const result = {};
  tags.forEach((tag, index) => {
    result[tag] = pages[index];
  });
  return result;
}

export function getBestBetsFromPages(data) {
  if (data && data.best_bets && data.best_bets.pages) {
    return data.best_bets.pages.edges || [];
  }
  return [];
}

export function getRelatedTopics(data, tags) {
  const relatedTopics = {};
  tags.forEach((tag) => {
    if (tag !== "best_bets") {
      if (data && data[tag] && data[tag].pages) {
        relatedTopics[tag] = data[tag].pages.edges || [];
      } else {
        relatedTopics[tag] = [];
      }
    }
  });
  return relatedTopics;
}

export async function getTagsPages() {
  const data = await getPagesWithTags(PAGES_TAGS);
  const bestBets = getBestBetsFromPages(data);
  const relatedTopics = getRelatedTopics(data, PAGES_TAGS);
  return { bestBets, relatedTopics };
}
