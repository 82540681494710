import { useRouter } from "next/router";
import { createContext, useEffect, useMemo, useState } from "react";

import { SOCCER_LEAGUES } from "@/config/constants";
import { CONSENSUS_PICKS_MENU } from "@/config/menus";
import { getHorseTracks } from "@/pages/api/apiBettingsNews";
import {
  getAffiliates,
  getBanners,
  getCategoryUris,
  getPagesToSidebar,
} from "@/pages/api/apiWP";
import { getLeagueFromRoute } from "@/utilities/league";
import { getTagsPages } from "@/utilities/pagesTags";
import { useActivateShortCodeStyleSheets } from "@/utilities/shortcodes";

const AppContext = createContext();

function AppProvider({ children }) {
  const router = useRouter();
  const [selectedLeague, setSelectedLeague] = useState(
    CONSENSUS_PICKS_MENU.all.id
  );
  const [affiliates, setAffiliates] = useState(null);
  const [banners, setBanners] = useState(null);
  const [categoryUris, setCategoryUris] = useState(null);
  const [pagesToSidebar, setPagesToSidebar] = useState(null);
  const [bestBetsPages, setBestBetsPages] = useState([]);
  const [matchupTopicRelatedPages, setMatchupTopicRelatedPages] = useState({});
  const [newsPick, setNewsPick] = useState(null);
  const [horsesTracks, setHorsesTracks] = useState(null);

  useActivateShortCodeStyleSheets();

  useEffect(() => {
    const updateLeague = () => {
      const currentLeague = getLeagueFromRoute(
        router.query.archive,
        router.query.category,
        router.query.path
      );
      const updatedLeague =
        CONSENSUS_PICKS_MENU[
          SOCCER_LEAGUES.includes(currentLeague) ? "soccer" : currentLeague
        ]?.id || CONSENSUS_PICKS_MENU.all.id;
      setSelectedLeague(updatedLeague);
    };

    router.events.on("routeChangeComplete", updateLeague);
    updateLeague(); // Initial call on component mount

    return () => {
      router.events.off("routeChangeComplete", updateLeague);
    };
  }, [router.events, router.query]);

  useEffect(() => {
    const fetchData = async () => {
      if (!affiliates) {
        const fetchedAffiliates = await getAffiliates();
        setAffiliates(fetchedAffiliates);
      }
      if (!banners) {
        const fetchedBanners = await getBanners();
        setBanners(fetchedBanners);
      }
      if (!categoryUris) {
        const fetchedCategoryUris = await getCategoryUris();
        setCategoryUris(fetchedCategoryUris);
      }
      if (!pagesToSidebar) {
        const fetchedPagesToSidebar = await getPagesToSidebar();
        setPagesToSidebar(fetchedPagesToSidebar);
      }
      if (!horsesTracks) {
        const fetchedHorsesRacing = await getHorseTracks();
        setHorsesTracks(fetchedHorsesRacing);
      }

      const { bestBets, relatedTopics } = await getTagsPages();
      setBestBetsPages(bestBets);
      setMatchupTopicRelatedPages(relatedTopics);
    };
    fetchData();
  }, [affiliates, banners, categoryUris, horsesTracks, pagesToSidebar]);

  const appState = useMemo(
    () => ({
      selectedLeague,
      setSelectedLeague,
      affiliates,
      banners,
      categoryUris,
      pagesToSidebar,
      bestBetsPages,
      matchupTopicRelatedPages,
      newsPick,
      setNewsPick,
      horsesTracks,
      setHorsesTracks,
    }),
    [
      selectedLeague,
      affiliates,
      banners,
      categoryUris,
      pagesToSidebar,
      bestBetsPages,
      matchupTopicRelatedPages,
      newsPick,
      horsesTracks,
    ]
  );

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
}

export { AppContext, AppProvider };
